import React from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import { Chessboard } from "react-chessboard";
import { Chess } from 'chess.js'
import { useEffect,useState } from 'react';
import {Grid,Dialog,DialogContentText,DialogContent,DialogActions ,DialogTitle ,Button } from "@material-ui/core"
import "./App.css"
import "./App.scss"

const App = () => {
 const chess = new Chess();
 
  const [game,setGame]=useState('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR');
  const [open,setOpen]=useState(false);
  const [Loader,setLoader]=useState(0);
  const [disable, setDisable] = useState(false);
  const [isChance, setisChance] = useState(false);
  const [Next,SetNext]=useState("Start")
  let [moveno,SetMoveNo]=useState(-1);
  let [Guess,SetGuess]=useState("");
  const [ChessGame,SetChessGame]=useState(new Chess());
  const [points,SetPoints ]=useState(0);
  var [TotalPoints,SetTotalPoints]=useState(0);
  const [WhiteElo, setWhitEelo] = useState("");
  const [BlackElo, setBlackElo] = useState("");
  const [AvgElo, setAvgElo] = useState("");
  const [PgnGame, setPgnGame] = useState("");
  const [WhitePlayer, setWhitePlayer] = useState("");
  const [BlackPlayer, setBlackPlayer] = useState("");
  const [Link, setLink] = useState("");
  const [Result, setResult] = useState("");
  const [whiteClock, setwhiteClock] = useState([]);
  const [blackClock, setblackClock] = useState([]);

  const [whiteTime, setwhiteTime] = useState("");
  const [blackTime, setblackTime] = useState("");

  const [displayResult,setDisplayResult]=useState(false);

        
      let odd = [];
    let even = [];

    useEffect(() => {
      window.addEventListener('keydown', (event) => {        
        if(Next!=="Start")
        {
          if(event.key==="ArrowRight"||event.key==="ArrowUp")
          {              
          }
          if(event.key==="ArrowLeft"||event.key==="ArrowDown")
          {                       
          }
        }
      })},[Next]);
      
      // useEffect(() => {}, [whiteTime,blackTime]);
    const fetchGame=async()=>{      
      setLoader(1);
      const response=await fetch('https://guesselobackend.onrender.com/api/fetch',
      {
       method:"GET",
       headers: {          
          'Content-Type': 'application/json'
        },
       mode: 'cors',            
        referrerPolicy: "no-referrer", // no-referrer-when-downgrade, no-referrer, origin, same-origin...
      });            
      const res=await response.json();            
      const data=res.result;          
      console.log(res);    
      if (response.ok) 
      {
        setwhiteClock(res.whiteclock);                        
        const time=await res.blackclock;                                                  
  
        setBlackElo(data.blackelo)
        setBlackPlayer(data.blackplayer)
        setLink(data.link)                
        setWhitEelo(data.whiteelo)
        setResult(data.result)
        setWhitePlayer(data.whiteplayer)
        setResult(data.result)
        setAvgElo((parseInt(data.whiteelo)+parseInt(data.blackelo))/2);                                        
        setPgnGame(data.pgn);
        setblackClock(time);
        setwhiteTime(res.whiteclock[0]);
        setblackTime(res.blackclock[0]);
        console.log(blackClock);        
        //console.log(BlackElo,BlackPlayer,Link,PgnGame,WhiteElo,WhitePlayer,Result);
      }
      setLoader(2);
    }
 
 
  const pgn = [PgnGame];
         
chess.loadPgn(pgn.join('\n'));
var moves = chess.history();

const NextMove = (e) => {
    
  e.preventDefault();
    if(moves[moveno+1]!==undefined)
      {
        SetMoveNo(++moveno);    
        if(moveno%2===0)
        {
          setwhiteTime(whiteClock[moveno/2]);
          setisChance(true);
        }
        else{          
          setblackTime(blackClock[Math.floor(moveno/2)]);
          setisChance(false);
        }
  
      ChessGame.move(moves[moveno]);
      const fen = ChessGame.fen()
      setGame(fen);    
      
      }
      else
      {
      //   SetMoveNo(--moveno);    
      //   setDisable(true);
      }
  }
  
  
const PrevMove = (e) => {
    e.preventDefault();
    if(moves[moveno]!==undefined&&moveno>0)
      {
      
      SetMoveNo(--moveno);    
      if(moveno%2===0)
      {
        setwhiteTime(whiteClock[moveno/2]);
        setisChance(true);
      }
      else{
        setblackTime(blackClock[Math.floor(moveno/2)]);
        setisChance(false);
      }

      ChessGame.undo();
      const fen = ChessGame.fen()
      //console.log(fen);    
      //console.log(moveno);
      setGame(fen);          
      }
    //       if(disable)
    // {
    //   setDisable(false);
    // }

  }
  const handleSubmit = (event) => {
    event.preventDefault();
    setOpen(true)
    setDisplayResult(true);
    const avgElo=(parseInt(BlackElo)+parseInt(WhiteElo))/2;
    
    SetPoints(Math.abs(Math.round(Math.abs(Guess-avgElo))>100?0:Math.round(Math.abs(Guess-avgElo-100))));    
    SetTotalPoints(TotalPoints+=points);
  }

  const handleNext = (event) => {    
    event.preventDefault();
    setGame('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR');
    SetChessGame((new Chess()));
    SetGuess("");
    setPgnGame("");
    fetchGame();  
    SetMoveNo(-1);
    SetNext("Next Game");
  }







  return (    
  <div className="Container">    
    <Grid container >
      <Grid item  lg={8} sm={6} xs={6}><div className='Heading'><h1>Guess Elo </h1><span>Lichess Ratings</span></div></Grid>
      <Grid item  lg={4} sm={6} xs={6}><div className='NextButton'><button onClick={handleNext}>{Next}</button></div></Grid>
      
      <Grid item lg={12}  xs={12} sm={12}>      
      {Loader!==1 ?
        <div className="ChessBoardContainer">
          <p className={isChance ? "NotChance":"Chance"}>{BlackPlayer}<span>{blackTime}</span></p>
          <Chessboard position={game} arePiecesDraggable={false} customDarkSquareStyle={{ backgroundColor:'#769456' }} customLightSquareStyle={{backgroundColor:"#eeefd3"}}/> 
          <div className='BoardButtons'>
      <p className={isChance ? "Chance":"NotChance"}>{WhitePlayer}<span>{whiteTime}</span> </p>     
      <button className="Prev" onClick={PrevMove} disabled={Loader!==2}>Prev</button>
      <button  className="Next" onClick={NextMove} disabled={Loader!==2}>Next</button>    
      </div>
      <div className="FormContainer">
  <form onSubmit={handleSubmit}>  
      <input
          type="number"           
          min="1"
          required
          onChange={(e) => SetGuess(e.target.value)}
          value={Guess}
          
        />        
      <button type="submit" >Guess Elo</button>          
         </form>               
         </div>       
        </div> 
              :<div className='Loader'>&#9812;&#9813;</div>}
       
         </Grid>  
  <Grid item lg={8} xs={12} sm={12} >
    
         <Dialog open={open} fullWidth onClose={()=>setOpen(false)} aria-labelledby='dialog-title' aria-describedby='dialog-description'>
          <DialogTitle id="dialog-title">Your Guess:{Guess}</DialogTitle>
          <DialogContent id="dialog-description">
            <DialogContentText component={'span'}>
         {
         displayResult ?
      <div className='InfoContainer'>
      <div>Average Elo : {AvgElo}</div>    
      <div>Black Elo : {BlackElo}</div>
      <div>WhiteElo : {WhiteElo}</div>
      <div>Result : {Result}</div>
      <div>Total Points : {TotalPoints}</div>
      <div>Your Score:{points}</div>
      <div><a href={Link}>Game Link</a></div>
      </div>
               :
          <div></div>
        }     
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={()=>{                            
              setPgnGame("");
              setGame('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR');
              SetChessGame((new Chess()));
              fetchGame();
              SetGuess("");
              SetMoveNo(-1);
              setOpen(false);              
              }}>Next Game!</Button>            
          </DialogActions>
         </Dialog>
         </Grid>                  
      </Grid>
      </div>              
  )
}
export default App

